import React from "react";
import Slider from "react-slick";
import { ItemColumnCarousel } from "./itemColumnCarousel";
import { girlBlackLaptop, girlPiggyBank, threePeople } from "../../../assets";
import { ContentSlider } from "./index.module";

const settings = {
  dots: false,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 20000,
  margin: 0,
  initialSlide: 0,
  centerMode: true,
  infinite: false,
  centerPadding: 0,
  appendDots: (dots) => <ul style={{ bottom: "-20px" }}>{dots}</ul>,
  customPaging: (i) => <span className="ft-slick__dots--custom"></span>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

const SliderInfoQueTipos = () => {
  return (
    <ContentSlider>
      <section className="tw-py-12 tw-py-16@lg bg-gray-6">
        <div className={"containerWrap"}>
          <h2 className="title tw-mt-0">
            ¿Qué tipo de fondo me conviene?
          </h2>

          <div className="sub-title">
            Elegir un tipo de fondo es una decisión importante, por eso debes
            considerar tres criterios fundamentales:
          </div>

          <Slider {...settings}>
            <div className={`tw-pt-2 tw-pb-3 tw-px-3`}>
              <ItemColumnCarousel
                bgColor="bg-yellow-1"
                srcImage={threePeople}
                altImage="three-people"
                title="Tu edad"
                description="Ten en cuenta cuánto tiempo falta para que te jubiles. Así puedes decidir entre un tipo de fondo conservador o de mayor riesgo."
              />
            </div>

            <div className={`tw-pt-2 tw-pb-3 tw-px-3 tw-pl-3@lg tw-pr-3@lg`}>
              <ItemColumnCarousel
                bgColor="bg-turquoise-1"
                srcImage={girlPiggyBank}
                altImage="girl-piggy-bank"
                title="Tus ingresos futuros"
                description="Si tu pensión de jubilación será tu principal ingreso cuando te jubiles, debes evaluar que tanto puedes arriesgar tu fondo."
              />
            </div>

            <div className={`tw-pt-2 tw-pb-3 tw-px-3 tw-pl-4@lg tw-pr-2@lg`}>
              <ItemColumnCarousel
                bgColor="bg-orange-4"
                srcImage={girlBlackLaptop}
                altImage="girl-black-laptop"
                title="Tu tolerancia al riesgo"
                description="Considera que tanto estás dispuesto a arriesgar al buscar más rentabilidad para tu dinero."
              />
            </div>
          </Slider>
        </div>
      </section>
    </ContentSlider>
  );
};

export default SliderInfoQueTipos;
