import styled from "styled-components";

export const ContentSlider = styled.div`
  .tw-pt-2 {
    padding-top: 8px !important;
  }
  .tw-pb-3 {
    padding-bottom: 12px !important;
  }
  .tw-px-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .tw-py-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .bg-gray-6 {
    background-color: #f8f9f9;
  }  
  .tw-pr-2\@lg {
    padding-right: var(--unit-05) !important;
  }
  .tw-pl-3\@lg {
    padding-left: var(--unit-075) !important;
  }
  .tw-pr-3\@lg {
    padding-right: var(--unit-075) !important;
  }
  .tw-pl-4\@lg {
    padding-left: var(--unit-1) !important;
  }
  .containerWrap {
    padding: 0 18px;
    h2 {
      font-weight: 800;
      font-size: 20px;
      line-height: 26px !important;
      margin-bottom: 4px !important;
      text-align: center;
      color: #45494e;
      margin-top: 0 !important;
    }
    .title{
      font-family: "Lexend";
      font-weight: 600;
      font-size: 20px;
      line-height: 32px !important;
      margin: 0 !important;
      text-align: center;
    }
    .sub-title {
      font-family: Calibri;
      color: #45494e;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px !important;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px !important;
    }
  }

  .ft-slick__dots--custom {
  background: #d9d9d9;
  font-size: 12px !important;
  width: 16px;
  height: 16px;
  border-radius: 15px;
  display: inline-block;
}

.slick-active .ft-slick__dots--custom {
  position: relative;
  top: 1px;
  background: #c8c8c8;
  width: 20px;
  height: 20px;
}

  @media (min-width: 1280px) {
    .containerWrap {
      width: 1080px;
      margin: 0 auto;
      padding: 0px;
    }
  }
`;
export const ItemColumnCarouselStyle = styled.div`
  height: 400px;
  border-radius: 16px !important;
  box-shadow: 2px 2px 10px 2px #e4e5e5;
  overflow: hidden;
  .tw-pt-2 {
    padding-top: 8px !important;
    img {
      margin-left: auto;
      margin-right: auto;
      display: block !important;
      height: 200px;
    }
  }
  .tw-p-8 {
    padding: 32px !important;
    .font-family-secondary {
      font-family: "Lexend";
      color: #45494e;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px !important;
      margin: 0 !important;
    }
    .font-family-primary {
      font-size: 16px;
      line-height: 20px !important;
    }
  }
  .bg-orange-4 {
    background-color: #ffede5;
  }
  .bg-turquoise-1 {
    background: #e5fafa;
  }
  .bg-yellow-1 {
    background-color: #fff7d9;
  }
  @media (min-width: 1024px) {
    height: 392px;
  }
`;
