import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

export const initialState = {

    DNI:'dynatraceDNI_DNI',
    skipReco: false,
    startFlow: false,
    urlFlow: "",
    showLoading: false,

    showModalCTA: true,
    withoutHelp: false,
    
    modalOptions: {
        isActive: false,
        title: "",
        content: "",
        type: "",
        textButton: "",
        returnStep: "",
        className: "",
    },

    TCmodalOptions: {
        isActive: false,                     
        title: "",
        content: "",
        type: "",       
    },

    testABOption: 4,
    messagePage: '',
    stepCurrent: 0,
    lastStep: 0,
    codeValidateDocument: 1, 
    validReniecActive: true,
    validSecontReniecActive: true,

    isConsultedReniec: false,

    isCorrectData: null,
    showQueryResponse: false,
    showPersonalData: false,
    showContactData: false,
    isAddressDNI:  true,
    confirmationTC: false,
    showDivBenefits: true,
    finishCircuit: false,

    // Step 2
    showTypesFound : false,
    // Step 3
    imageBiometric: null,
    biometricStatus: '',
    biometricVerified: false,
    maxAttemps: "5",
    AttemptsQ: 3,

    // Step 4
    showAttention: false,
    showFrameAssesor: true,
    haveAsesor: false,
    heldPosition: null,
    stepsFinished: false,
    tokenStep : null, 

    //Manejo de errores inputs
    errorDocNumberInput: false,
    errorPhoneInput: false,
    errorPhoneInputCTA: false,
    errorEmailInput: false,
    errorEmailCinput: false,
    descriptionHousingInput: false,
    descriptionViaInput: false,
    districtCodeInput: false,
    provinceCodeInput: false,
    departmentCodeInput: false,

    //Manejo de abandono por paso
    enabledAbandonmentStep1: false,
    legaltext: '',
    whatslink: '',
    userAgent: '',
    device: '',
    typesList: [        
        {id:1, type:1, name:'Fondo 1', content:'Crecimiento con seguridad, perfecto si buscas un balance sin mucho riesgo.', strongDescription:''},
        {id:2, type:2, name:'Fondo 2', content:'Crecimiento equilibrado, ideal si eres paciente y quieres más rendimiento.', strongDescription:''},
        {id:3, type:3, name:'Fondo 3', content:'Crecimiento alto, riesgo alto. Ideal para quienes piensan a largo plazo.', strongDescription:''},
    ],

    userWeb: {
        //Variable para determinar si se usa TOC o facephi
        useToc:true,
        
        // Step 0
        initialCheckbox: null,
        age: null,

        // Step 1
        documentType: "00",
        documentNumber: "",

        //Authorization
        tcConfirmation: false,
        adpConfirmation: false,
        minimumConfirmation: false,
        
        //idBiometrico
        biometric:null,
        flagPermisssionCamera: null, 

        //Preguntas PEP
        pepInstitutionName:'',
        pepPositionHeld:'',
        heldPosition: null,

        firstName:null,
        email: '',
        emailConfirmation: '',
        phoneNumber: '',
        phoneNumberCTA:'',
        codeHousing: '',
        descriptionHousing: '',
        addressReference: '',
        departmentCode: '',
        provinceCode: '',
        
        //Valores para la referencia de dirección
        codeViaValue: '',
        codeVia: null,
        descriptionVia: '',
        districtValue: '',
        districtCode: null,
        address: '',

        // Step 2
        workerType: 'I',
        rucEmployer: "",
        tradeNameEmployer: "",
        codeFundType: 2,
        fundType: 2,

        promoterCode: "", 
        promoterCodeUser: "",
        testABOption: 4,
    },

    userWebTransfer: {

    },

    listGenerals: {},

    userTransferStatus: {},
};

export const __reducer = (state, action) => {
    if(action.type == 'BACK_BUTTON_CLICKED'){
        if(window.location.href.includes('pasos0') ){
            return {
                ...state,
                stepCurrent: 0,
            };
        }
        if(window.location.href.includes('pasos1') ){
            return {
                ...state,
                stepCurrent: 1,
            };
        }
        if(window.location.href.includes('pasos2') ){
            return {
                ...state,
                stepCurrent: 2,
            };
        }
        if(window.location.href.includes('pasos3') ){
            if(window.location.href.includes('pasos3validacion-biometrica') ){
                return {
                    ...state,
                    stepCurrent: 3,
                };
            }
            return {
                ...state,
                stepCurrent: 3,
            };

        }
        if(window.location.href.includes('pasos4') ){
            return {
                ...state,
                stepCurrent: 4,
            };
        }

    }
    switch (action.type) {

        case 'INITIAL_STATE':
            return { ...initialState, listGenerals: state.listGenerals, legaltext: state.legaltext, whatslink: state.whatslink};

        case 'FIELD':
            return { ...state, [action.payload.field]: action.payload.value };
        case 'FIELD_SECOND':
            return {
                ...state,
                [action.payload.field1]: {
                    ...state[action.payload.field1],
                    [action.payload.field2]: action.payload.value
                }
            };

        case 'VALID_DOCUMENT':
            return {
                ...state,
                showPersonalData: true,
                showContactData: true,
                isAddressDNI: action.payload.isAddressDNI,
                userWebTransfer: { ...action.payload.userWebTransfer },
                userWeb: { ...state.userWeb, ...action.payload.userWeb },
                isConsultedReniec: action.payload.validReniec,
                enabledAbandonmentStep1: action.payload.enabledAbandonmentStep1,
            };
            
        case 'RETURN_STEP_1':
            return {
                ...state,
                showPersonalData: true,
                showContactData: true,
                isCorrectData: true,
                isConsultedReniec: true,
                stepCurrent: 1,
                isAddressDNI: true,
                userWebTransfer: { ...action.payload.userWebTransfer },
                userWeb: { ...state.userWeb, ...action.payload.userWeb },
            };
        
            case 'RETURN_STEP_1_INPUTS':
                return {
                    ...state,
                    showPersonalData: false,
                    showContactData: false,
                    isCorrectData: false,
                    isConsultedReniec: false,
                    stepCurrent: 1,
                    isAddressDNI: true,
                };

        case 'NOT_VALID_DOCUMENT':
            return {
                ...state,
                showPersonalData: false,
                showContactData: false,
                modalOptions: {
                    idModal: action.payload.idModal || "genericId", 
                    isActive: true,
                    title: action.payload.title || "Aviso",
                    content: action.payload.content || "Ocurrió un error interno, por favor vuelva a intentar en unos momentos.",
                    type: action.payload.type || "alert",
                    textButton: action.payload.textButton || "Cerrar",
                    className: action.payload.className || ""
                },
            };
        
        case 'NOT_VALID_DOCUMENT_NO_ASSESOR':
            return {
                ...state,
                stepCurrent: 0,
                showPersonalData: false,
                showContactData: false,
                modalOptions: {
                    idModal: action.payload.idModal || "genericId", 
                    isActive: true,
                    title: action.payload.title || "Aviso",
                    content: action.payload.content || "Ocurrió un error interno, por favor vuelva a intentar en unos momentos.",
                    type: action.payload.type || "alert",
                    textButton: action.payload.textButton || "Cerrar",
                    className: action.payload.className || ""
                },
            };
        
        case 'SHOW_TCMODAL':
            return {
                ...state,
                TCmodalOptions: {
                    isActive: true,
                    title: action.payload.title,
                    type: action.payload.type,
                    value: action.payload.value
                },
            };

        case 'VALID_CONTACT_DATA':
            return { ...state, showContactData: true };

        case 'VALID_PERSONAL_DATA':
            return { ...state, showContactData: true };
        case 'NOT_VALID_PERSONAL_DATA':
            return {
                ...state,
                userWeb: { ...state.userWeb, documentNumber: '' },
                showPersonalData: false,
                showContactData: false,
                isCorrectData: true
            };

        case 'UPDATE_CONTEXT':
            return {
                ...state,
                heldPosition:action.payload.heldPosition,
                userWeb: {
                    ...state.userWeb,
                    age: action.payload.age,
                    documentType: action.payload.documentType,
                    documentNumber: action.payload.documentNumber,
                    tcConfirmation: action.payload.tcConfirmation,
                    
                    adpConfirmation: action.payload.adpConfirmation,
                    pepInstitutionName: action.payload.pepInstitutionName,
                    pepPositionHeld: action.payload.pepPositionHeld,
                    heldPosition: action.payload.heldPosition,
                    firstName: action.payload.firstName,

                    email: action.payload.email,
                    emailConfirmation: action.payload.email,
                    phoneNumber: action.payload.phoneNumber,
                    address: action.payload.address,
                    workerType: action.payload.workerType,
                    
                    rucEmployer: action.payload.rucEmployer,
                    tradeNameEmployer: action.payload.tradeNameEmployer,
                    codeFundType: action.payload.codeFundType,
                    fundType: action.payload.fundType,
                    lastName: action.payload.lastName,
                    sureName: action.payload.sureName,
                    
                    testABOption: action.payload.testABOption,

                }
            };

        case 'COMPLETE_IDENTITY_STEP':
            return { ...state, stepCurrent: 2, userWebTransfer: { ...action.payload } };

        case 'COMPLETE_FUND_TYPE_STEP_NOT_TOC':
            return { ...state, stepCurrent: 3, userWebTransfer: { ...action.payload } };

        case 'COMPLETE_FUND_TYPE_STEP':
            return { ...state, stepCurrent: 2, userWebTransfer: { ...action.payload } };

        case 'BACK_FUND_TYPE_STEP':
            var showButton = true;
            return { ...state, stepCurrent: 1,showButton };

        case 'VALIDATION_BIOMETRIC':
            return { ...state, biometricVerified: true, stepCurrent: 3 };
        case 'NOT_VALIDATION_BIOMETRIC':
            return { ...state, biometricVerified: false, stepCurrent: 3 };
        case 'BACK_MAXIMUN_ATTEMPTS':
            return { ...initialState, listGenerals: state.listGenerals, stepCurrent: 1 };

        case 'COMPLETE_BIOMETRIC_STEP':
            return { ...state, stepCurrent: 4 };

        case 'BACK_TO_STEP_4':
            return { ...state, stepCurrent: 4 };
            
        case 'GO_TO_FINISH_PAGE':
            return { ...state, stepCurrent: 5 };

        case 'BACK_BIOMETRIC_STEP':
            return { ...state, biometricVerified: false, stepCurrent: 2 };

        case 'COMPLETE_DOCUMENTATION_STEP':
            return {
                ...state,
                stepsFinished: true,
                userWeb: {
                    ...state.userWeb,
                    danaId1: action.payload.danaId1,
                    danaId2: action.payload.danaId2,
                    idPhotoWidget: action.payload.idFile1,
                    promoterCodeUser: action.payload.promoterCodeUser,
                }
            };

        case 'BACK_DOCUMENTATION_STEP':
            return {
                ...state,
                stepsFinished: false,
                stepCurrent: 3,
            };

        case 'SET_CONFIG_MODAL':
            return {
                ...state,
                modalOptions: {
                    idModal: action.payload.idModal || "genericId", 
                    isActive: true,
                    title: action.payload.title || "Aviso",
                    content: action.payload.content || "Ocurrió un error interno, por favor vuelva a intentar en unos momentos.",
                    type: action.payload.type || "alert",
                    textButton: action.payload.textButton || "Cerrar",
                    returnStep: action.payload.returnStep || "",
                    className: action.payload.className || ""
                },
                showLoading: (action.payload.changeLoading !== undefined ? action.payload.changeLoading : state.showLoading)
            };

        case 'CLOSE_MODAL':
            return {
                ...state,
                modalOptions: {
                    isActive: false,
                },

            };

        case 'CLOSE_TC_MODAL':
            return {
                ...state,
                TCmodalOptions: {                    
                    title: "",
                    content: "",
                    type: "",                    
                },

            };
        case 'SET_LIST_GENERAL':
            return {
                ...state,
                listGenerals: {
                    ...(state.listGenerals),
                    ...action.payload
                }
            };

        case 'SET_RESPONSE_STATUS':
            return {
                ...state,
                userTransferStatus: {
                    ...action.payload
                }
            };          

        default: return state;
    }
};

const StateContext = createContext();
const DispatchContext = createContext();

const DigitalTransfersProvider = ({ children }) => {

    const [state, dispatch] = useReducer(__reducer, initialState);

    const handlePopstate = () => {
        // Aquí puedes realizar acciones específicas cuando se detecta el botón de retroceso
        // Puedes modificar la variable del contexto utilizando la función de despacho
        dispatch({ type: 'BACK_BUTTON_CLICKED' });
    };

    useEffect(() => {
        // Agrega el evento popstate al montar el componente
        window.addEventListener('popstate', handlePopstate);
    
        // Elimina el evento al desmontar el componente para evitar posibles fugas de memoria
        return () => {
          window.removeEventListener('popstate', handlePopstate);
        };
      }, []); // 

    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
                {children}
            </StateContext.Provider>
        </DispatchContext.Provider>
    );
};

export const useStateDigitalTransfersContext = () => useContext(StateContext);
export const useDispatchDigitalTransfersContext = () => useContext(DispatchContext);

export default DigitalTransfersProvider;

DigitalTransfersProvider.propTypes = {
    children: PropTypes.any.isRequired,
};