import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import DigitalTransfersProvider from '../context/DigitalTransfersContext';
import { QueryPage } from '../pages/query/QueryPage';
import Landing, { LandingPage } from '../../landing/index';
//archivos con asesor
import  PublicPagesLayoutAF   from '../../App/pages/layout-con-asesor/StepsDTLayout';
import  StepInitialLayoutAF   from '../../App/pages/layout-con-asesor/StepInitialLayout.jsx';
import { BiometricSelfiePageAF } from '../pages/paginas-con-asesor/BiometricSelfiePage';
import { FinishPageAF } from '../pages/paginas-con-asesor/FinishPage';
import { HomePageAF } from '../pages/paginas-con-asesor/InitialPage';
import { StepsPageAF } from '../pages/paginas-con-asesor/StepsPage';
//archivos sin asesor
import  PublicPagesLayout   from '../../App/pages/layout-sin-asesor/StepsDTLayout';
import { BiometricSelfiePage } from '../pages/paginas-sin-asesor/BiometricSelfiePage';
import { FinishPage } from '../pages/paginas-sin-asesor/FinishPage';
import { StepsPage } from '../pages/paginas-sin-asesor/StepsPage';
import PublicPagesLayoutNoBanner from '../../App/pages/layout-sin-asesor/PublicPagesLayout';


const DigitalTransfersModuleRouter = () => {
    //Componentes para el flujo de asesor
    const layoutComponentStep0 = (module) => {
        return (
          <StepInitialLayoutAF>
            {
                module()
            }
          </StepInitialLayoutAF>
        );
    };

    const layoutComponentAssitedFlow = (module) => {
        return (
          <PublicPagesLayoutAF>
            {
                module()
            }
          </PublicPagesLayoutAF>
        );
    };


    //Componentes para el flujo sin asesor
    const layoutComponent = (module) => {
        return (
          <PublicPagesLayout>
            {
                module()
            }
          </PublicPagesLayout>
        );
    };

    const layoutComponentNoBanner = (module) => {
        return (
          <PublicPagesLayoutNoBanner>
            {
                module()
            }
          </PublicPagesLayoutNoBanner>
        );
    };

    return (
        <DigitalTransfersProvider>
            <Switch>
                <Route
                    exact
                    path={"/"}
                    component={()=> layoutComponentNoBanner(Landing)}
                /> 
                <Route
                    exact
                    path={"/asesor/pasos0"}
                    component={()=> layoutComponentStep0(HomePageAF)}
                /> 
                <Route
                    exact
                    path={"/asesor/pasos1"}
                    component={()=> layoutComponentAssitedFlow(StepsPageAF)}
                />
                <Route
                    exact
                    path={"/asesor/pasos2"}
                    component={()=> layoutComponentAssitedFlow(StepsPageAF)}
                />
                <Route
                    exact
                    path={"/asesor/pasos3"}
                    component={()=> layoutComponentAssitedFlow(StepsPageAF)}
                />
                <Route
                    exact
                    path={"/asesor/pasos4"}
                    component={()=> layoutComponentAssitedFlow(StepsPageAF)}
                />
                <Route
                    exact
                    path={"/asesor/pasos3validacion-biometrica"}
                    component={()=> layoutComponentAssitedFlow(BiometricSelfiePageAF)}
                />
                <Route
                    exact
                    path={"/asesor/finalizacion-solicitud"}
                    component={()=> layoutComponentAssitedFlow(FinishPageAF)}
                />


                {/* Rutas para el flujo sin asesor */}
                <Route
                    exact
                    path={"/pasos0"}
                    component={()=> layoutComponent(StepsPage)}
                />
                <Route
                    exact
                    path={"/pasos1"}
                    component={()=> layoutComponent(StepsPage)}
                />
                <Route
                    exact
                    path={"/pasos2"}
                    component={()=> layoutComponent(StepsPage)}
                />
                <Route
                    exact
                    path={"/pasos3"}
                    component={()=> layoutComponent(StepsPage)}
                />
                <Route
                    exact
                    path={"/pasos3-recomendaciones"}
                    component={()=> layoutComponent(StepsPage)}
                />
                <Route
                    exact
                    path={"/pasos3validacion-biometrica"}
                    component={()=> layoutComponent(BiometricSelfiePage)}
                />
                <Route
                    exact
                    path={"/pasos4"}
                    component={()=> layoutComponent(StepsPage)}
                />
                <Route
                    exact
                    path={"/finalizacion-solicitud"}
                    component={()=> layoutComponent(FinishPage)}
                />

                {/* Otras rutas */}
                <Route
                    exact
                    path={"/traspasos-digitales/consulta"}
                    component={()=> layoutComponentNoBanner(QueryPage)}
                />
                <Redirect to="/" />


            </Switch>
        </DigitalTransfersProvider>
    );
};

export default DigitalTransfersModuleRouter;