import React, { useEffect, useRef } from "react";
import { LandingContainer } from "./Style";
import {
  fondoVideo,
  ejecutivaMovil,
  ejecutiva,
  tutorialTraspaso,
  tutorialTraspasoMovil,
} from "./assets";
import { accordionData, stepDatos } from "./constants/data";
import { redirectURL } from "./constants/functions";
import { sendToDataLanding } from "../digital-transfers/constants/sendToDataLayer";

import {
  FooterLanding,
  HeaderLanding,
  TimeLine,
  AccordionQuestions,
  TabsSlider,
  SliderInfoQueTipos,
} from "./components/index";
import { SeoLanding } from "./seoLanding";
import useIsMobile from "./hooks/useIsMobile";
import { Button } from "@prima-afp/prima-ui-design-system/dist/ui-components";
import Headband from "./experiment/components/headband/headband";
import MotorSlider from "./slider/motorSlider";

const Landing = () => {
  const sectionsRef = useRef({});
  const isMobile = useIsMobile();
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).margin;
    document.body.style.margin = "0";
    return () => {
      document.body.style.margin = originalStyle;
    };
  }, []);

  return (
    <>
      <SeoLanding />
      <LandingContainer>
        <HeaderLanding sectionsRef={sectionsRef} />
        <Headband />
        <MotorSlider />
        <SliderInfoQueTipos />

        {/* SECTION 1 */}
        <div
          className="section1"
          id="page1"
          ref={(el) => (sectionsRef.current["page1"] = el)}
        >
          <div className="contentSecction1">
            <div className="contentHeader1">
              <h4>Hazlo 100% digital y fácil</h4>
            </div>
            <div className="contentTitle1">
              <h2>Traspásate gratis en 3 pasos</h2>
            </div>
            <div className="contentBody1">
              <div className="contentVideo">
                <video
                  src={isMobile ? tutorialTraspasoMovil : tutorialTraspaso}
                  type="video/mp4"
                  className="videoPasos"
                  poster={fondoVideo}
                  controls
                  onClick={() => {
                    sendToDataLanding({
                      event: "video_interaction",
                      payload: {
                        section: "landing traspaso",
                        sub_section: "traspasate gratis en 3 pasos",
                        option: "video",
                      },
                    });
                  }}
                ></video>
              </div>
              <div className="contentPharaf1">
                <TimeLine items={stepDatos} />
                <Button
                  className="btnSlide"
                  label="Cámbiate hoy a Prima AFP"
                  onClick={() =>
                    redirectURL("traspaso", {
                      event: "button_click",
                      payload: {
                        section: "landing traspaso",
                        sub_section: "traspasate gratis en 3 pasos",
                        option: "cambiate hoy a prima afp",
                      },
                    })
                  }
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
        {/* FIN SECTION 1 */}

        {/* SECTION 2 */}
        <div
          className="section2"
          id="page2"
          ref={(el) => (sectionsRef.current["page2"] = el)}
        >
          <div className="contentSecction2">
            <div className="contentHeader2">
              <h4>Proceso de traspaso</h4>
            </div>
            <div className="contentTitle2">
              <h2>¿Cuánto toma ser parte de Prima AFP?</h2>
            </div>
            <div className="contentSilder">
              <TabsSlider />
            </div>
          </div>
        </div>
        {/* FIN SECTION 2 */}

        {/* SECTION 3 */}
        <div className="sectionAsesor">
          <div className="contentAsesor">
            <div className="imgSectionAsesor">
              {isMobile ? (
                <img src={ejecutivaMovil} alt="" />
              ) : (
                <img src={ejecutiva} alt="" />
              )}
            </div>
            <div className="contentSectionAsesor">
              <h2 className="titleAsesor">
                ¿Un asesor te está ayudando con tu solicitud?
              </h2>
              <div className="contentBtnAsesor">
                <Button
                  className="btnSlide"
                  label="Ingresa aquí"
                  onClick={() =>
                    redirectURL("traspasoAsesor", {
                      event: "button_click",
                      payload: {
                        section: "landing traspaso",
                        sub_section:
                          "un asesor te esta ayudando con tu solicitud",
                        option: "ingresa aqui",
                      },
                    })
                  }
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
        {/* FIN SECTION 3 */}

        {/* SECTION 4 */}
        <div
          className="section4"
          id="page4"
          ref={(el) => (sectionsRef.current["page4"] = el)}
        >
          <div className="containerSection">
            <div className="container">
              <h4 className="topTitleT">¿Aún tienes dudas?</h4>
              <h2 className="sectionTitleT">
                Te ayudamos a resolver tus preguntas frecuentes
              </h2>
            </div>
            <div className="box-accordion">
              <AccordionQuestions items={accordionData} />
            </div>
          </div>
        </div>
        {/* FIN SECTION 4 */}

        <FooterLanding />
      </LandingContainer>
    </>
  );
};

export default Landing;
