import React, { useEffect } from "react";

const OneSignalInitializer = () => {
  useEffect(() => {
    const loadOneSignalScript = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js";
      script.defer = true;

      script.onload = () => {
        window.OneSignalDeferred = window.OneSignalDeferred || [];
        window.OneSignalDeferred.push(async function (OneSignal) {
          const appId = process.env.REACT_APP_ONESIGNAL_ID;
          const safariWebId = process.env.REACT_APP_ONESIGNAL_ID_SAFARI;

          if (appId && safariWebId) {
            await OneSignal.init({
              appId,
              safari_web_id: safariWebId,
              promptOptions: {
                slidedown: {
                  prompts: [
                    {
                      text: {
                        actionMessage: "¡Mantente actualizado! Suscríbete a nuestro boletín",
                        acceptButton: "Aceptar",
                        cancelButton: "Después",
                      },
                    },
                  ],
                },
              },
            });
          } else {
            console.error("OneSignal App ID or Safari Web ID is missing!");
          }
        });
      };

      document.head.appendChild(script);
    };

    loadOneSignalScript();
  }, []);

  return null;
};

export default OneSignalInitializer;
