import useIsMobile from "modules/digital-transfers/hooks/useMobile";
import React, { useEffect, useState } from "react";
import { ButtonFloatingStyle } from "./style";
import { Button } from "@prima-afp/prima-ui-elements/dist/core";

export const ButtonFloating = ({ buttonRef, action, text }) => {
  const isMobile = useIsMobile();
  const [isVisibleButton, setIsVisibleButton] = useState(false);

  // Acción para validar si se muestra el botón
  useEffect(() => {
    // Función de callback para IntersectionObserver
    const observerCallback = (entries) => {
      const [entry] = entries;
      setIsVisibleButton(entry.isIntersecting); // Actualiza el estado según si está en la vista
    };

    // Configuración del IntersectionObserver
    const observer = new IntersectionObserver(observerCallback, {
      root: null, // Usa el viewport como root
      threshold: 0.1, // Dispara cuando al menos el 10% del elemento esté visible
    });

    // Comienza a observar el input
    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    // Limpia el observer cuando el componente se desmonta
    return () => {
      if (buttonRef.current) {
        observer.unobserve(buttonRef.current);
      }
    };
  }, [isMobile]);
  return (
    <>
      {!isVisibleButton && isMobile && (
        <ButtonFloatingStyle>
          <Button onClick={action}>{text}</Button>
        </ButtonFloatingStyle>
      )}
    </>
  );
};
