import React from "react";
import { ItemColumnCarouselStyle } from "./index.module";

export const ItemColumnCarousel = (props) => {
  const { bgColor, srcImage, altImage, title, description } = props;

  return (
    <ItemColumnCarouselStyle>
      <div className={`${bgColor} tw-pt-2`}>
        <img src={srcImage} alt={altImage} />
      </div>

      <div className={`tw-p-8`}>
        <h3 className={`font-family-secondary`}>{title}</h3>

        <div className={`font-family-primary`}>{description}</div>
      </div>
    </ItemColumnCarouselStyle>
  );
};
