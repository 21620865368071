import styled from "styled-components";

export const ButtonFloatingStyle = styled.div`
  z-index: 1;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background-color: #ff4f00;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 133%;
  font-family: Helvetica, sans-serif;
`;
