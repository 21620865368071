import styled from "styled-components";
export const TestimonialStyle = styled.div`
  display: "flex";
  height: 402px;
  overflow-y: hidden;
  .slick-list {
    height: auto !important;
    max-width: 335px !important;
  }
  .contentItem {
    width: 100%;
    max-width: 335px;
    min-height: 188.19px;
    margin-bottom: 38px;
    .contentGlobo {
      height: 87px;
      position: relative;
      border-radius: 20px;
      background-color: #fff;
      max-width: 335px;
      text-align: center;
      box-shadow: 0px 2px 4px 0px rgba(69, 73, 78, 0.04);
      box-shadow: 0px 16px 32px -4px rgba(69, 73, 78, 0.1);
      p {
        padding: 19.5px 17.59px 0px 17.33px;
        margin: 0px;
        font-family: Lexend;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.0012em;
        text-align: left;
        color: var(--Text-Gray-Default, rgba(78, 82, 88, 1));
      }
      ::before {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 30%;
        transform: translateX(-50%);
        border-width: 15px 15px 0px 0px;
        border-style: solid;
        border-color: #fff transparent;
        background-color: transparent;
        display: block;
        width: 0;
      }
    }
    .contentUser {
      display: flex;
      align-items: center;
      width: 212.92px;
      height: 70px;
      padding-top: 31.19px;
      .imgAvatar {
        width: 70px;
        height: 70px;
      }
      .nameUser {
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-left: 19.92px;
        width: 100%;
        img {
          width: 60.64px;
          height: 12.13px;
        }
        p {
          font-family: Lexend;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: left;
          color: var(--Text-Gray-Default, #4e5258);
          margin: 0px;
          padding-bottom: 13.86px;
        }
      }
    }
  }
  @media screen and (max-width: 376px) {
    width: 375px;
    .slick-list {
      max-width: 375px !important;
    }
    .contentItem {
      max-width: 315px;
      .contentGlobo {
        height: 87px;
        border-radius: 13.86px;
        background-size: cover;
        background-repeat: no-repeat;
        p {
          padding: 16px 17px 0px 20px;
        }
        ::before {
          bottom: -12px;
          left: 18%;
        }
      }
      .contentUser {
        display: flex;
        align-items: center;
        padding-top: 16px;
        .imgAvatar {
        }
        .nameUser {
          display: flex;
          flex-direction: column-reverse;
          align-items: start;
          padding-left: 19.92px;
          p {
            margin: 0px;
            padding-top: 3.47px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 376px) and (max-width: 700px)  {
    width: 100%;
    .slick-list{
      max-width: max-content !important;
      .slick-slide{
        display: flex;
        justify-content: center;
      }
    }
  }
`;
