import { devicesLanding } from "modules/utils/responsive";
import styled from "styled-components";
export const StyledDotsWrapper = styled.ul`
  list-style: none;
  display: flex !important;
  position: absolute;
  bottom: 40px;
  left: 99.5px;
  justify-content: flex-start;
  align-items: center;
  max-width: 1404px;
  transform: translateY(50%);
  @media (min-width: 1500px) {
    left: 50%;
    transform: translate(-50%, 50%);
  }
  @media screen and (min-width: 768px) and (max-width: 1500px) {
    left: 52% !important;
    transform: translate(-50%, 50%);
  }
  @media (max-width: 768px) {
    bottom: 20px;
    justify-content: center;
    left: 0;
    width: 100%;
  }

  li {
    /* margin: 0 6px; */
    display: flex;
    align-items: center;
    button {
      width: 14px;
      height: 14px;
      background-color: #ffffff;
      border-radius: 50%;
      border: none;
      opacity: 0.6;
      transition: all 0.3s ease;
      box-shadow: 0px 2px 4px 0px #45494e0a;
      transform: scale(1);
      &:before {
        display: none;
      }
    }

    &.slick-active button {
      opacity: 1;
      box-shadow: 0px 16px 32px -4px #45494e1a;
      background-color: #ffffff;
      transform: scale(1.4);
      transition: all 0.3s ease;
    }
  }
`;

// sliderItem
export const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 417px;
  justify-content: center;
  display: flex;
  /* Asignar fondo solo si existe la imagen o color */
  ${(props) =>
    props.backgroundImage?.desktop
      ? `background: url(${props.backgroundImage.desktop});`
      : ""}
  ${(props) =>
    props.background ? `background-color: ${props.background};` : ""}
  
  background-size: cover;
  /* Estilos para móviles */
  @media ${devicesLanding.mobile} {
    height: 525px;
    ${(props) =>
      props.backgroundImage?.movil
        ? `background: url(${props.backgroundImage.movil});`
        : ""}
  }

  /* Estilos para tablet */
  @media ${devicesLanding.tablet} {
    ${(props) =>
      props.backgroundImage?.desktop
        ? `background: url(${props.backgroundImage.desktop});`
        : ""}
    background-size: cover;
  }

  /* Estilos para desktop (default fallback a desktop) */
  @media ${devicesLanding.desktopMedium} {
    ${(props) =>
      props.backgroundImage?.desktopMedium
        ? `background: url(${props.backgroundImage.desktopMedium});`
        : ""}
    ${(props) =>
      props.background ? `background-color: ${props.background};` : ""}
  }

  @media ${devicesLanding.desktopLarge} {
    ${(props) =>
      props.backgroundImage?.desktopLarge
        ? `background: url(${props.backgroundImage.desktopLarge});`
        : ""}
    background-size: 100% 100%;
    ${(props) =>
      props.background ? `background-color: ${props.background};` : ""}
  }
`;

// Contenedor para cada slide
export const Slide = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1404px;
  align-items: center;
  display: flex;

  .conteinerLeft {
    width: 70%;
    height: 100%;
    .grayTextColor {
      color: #4e5258 !important;
    }
    .title {
      font-family: Lexend;
      font-size: 38px;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: 0.0012em;
      text-align: left;
      margin: 0px;
      padding-bottom: 16px;
      color: #fff;
    }
    .title2 {
      color: #ff4f00;
    }
    .subTitle {
      font-family: Lexend;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
      color: #fff;
    }
    .buttonContainer {
      padding-top: 32px;
      z-index: 2;
      position: relative;
      .btnSlide {
        padding: 16px 30px;
        border-radius: 16px;
        background-color: var(
          --Background-Primary-Default,
          rgba(255, 79, 0, 1)
        );
        font-family: Lexend;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.0012em;
        text-align: center;
        color: var(--Text-White-Default, rgba(255, 255, 255, 1));
      }
      .btnSlideSecundary {
        padding: 16px 20px;
        border-radius: 16px;
        border: 1.5px solid var(--Border-Primary-Default, rgba(255, 79, 0, 1));
        font-family: Lexend;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.0012em;
        text-align: center;
        color: var(--Text-Primary-Darker, rgba(238, 68, 11, 1));
        background-color: #fff;
        margin-left: 20px;
      }
      .pr-20 {
        margin-right: 20px;
        width: 284px;
      }
    }
  }
  .top92 {
    padding-top: 92px;
  }
  .top86 {
    padding-top: 86px;
  }
  .top66 {
    padding-top: 66px;
  }
  .content-right {
    width: 30%;
    display: flex;
    justify-content: end;
  }

  @media only screen and (min-width: 500px) and (max-width: 1317px){
    margin: 0px 20px;
  }

  @media only screen and (min-width: 1318px) and (max-width: 1499px) {
    max-width: 1318px;
    margin: 0px 20px;
  }
  @media only screen and (min-width: 1500px)  {
    max-width: 1404px;
  }

  @media ${devicesLanding.mobile} {
    flex-direction: column;
    justify-content: flex-start;
    .conteinerLeft {
      width: 100%;
      padding-left: 24px;
      padding-top: 40px;
      padding-bottom: 24px;
      padding-right: 10px;
      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.0012em;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding-bottom: 8px;
        display: inline;
      }
      .title2 {
        display: inline;
        margin: 0;
        padding: 0;
      }
      .subTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        max-width: 220px;
      }
      .buttonContainer {
        padding-top: 24px;
        padding-bottom: 20px;
        .btnSlide {
          width: 259px;
          padding: 12px 20px;
        }
        .btnSlideSecundary {
          padding: 11px 20px;
          margin-left: 0px;
          margin-top: 16px;
          width: 259px;
        }
        .pb-16 {
          margin-bottom: 16px;
          width: 259px;
        }
      }
    }
    .content-right {
      width: 100%;
      padding-top: 16px;
      justify-content: center;
    }
  }

  @media ${devicesLanding.tablet} {
    .conteinerLeft {
      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.0012em;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding-bottom: 8px;
        display: inline;
      }
      .title2 {
        display: inline;
        margin: 0;
        padding: 0;
      }
      .buttonContainer {
        .pb-16 {
          margin-bottom: 16px;
          width: 259px;
        }
        .btnSlide {
          width: 259px;
          padding: 12px 20px;
        }
        .btnSlideSecundary {
          padding: 11px 20px;
          margin-left: 0px;
          margin-top: 16px;
          width: 259px;
        }
      }
    }
  }
  @media ${devicesLanding.desktop} {
   
    .containerRight {
    }
  }
  @media ${devicesLanding.desktopMedium} {
    justify-content: space-between;
    .conteinerLeft {
      width: 70%;
    }
    .containerRight {
      width: 30%;
      display: flex;
      justify-content: end;
    }
  }
  @media ${devicesLanding.desktopLarge} {
    justify-content: space-between;
    .conteinerLeft {
      width: 70%;
    }
    .containerRight {
      width: 30%;
    }
  }
`;

export const Buttonp = styled.button`
  margin: 10px 5px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;

  &:hover {
    background: black;
  }
`;

export const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 207px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  z-index: 1;
  @media only screen and (max-width: 540px) {
    height: 124px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 11.6%,
      rgba(0, 0, 0, 0.13) 81.82%
    );
  }
`;
