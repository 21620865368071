import {
  backDesktop,
  backMovil,
  ModalLarge,
  ModalMedium,
  yapeDesktop,
  yapeDesktop2,
  yapeDesktop3,
  yapeMovil,
  wfDesktop,
  wfMovil,
  wfDesktop2,
  wfDesktop3,
  carDesktop,
  carMovil,
  carDesktop2,
  carDesktop3,
  slide3Desktop,
  slide3DesktopLarge,
  slide3Movil,
  slide3Tablet,
  worldMovil,
  worldDesktop,
  worldDesktop2,
  worldDesktop3,
  rentabilidadMovil,
  rentabilidadDesktop,
  rentabilidadDesktop2,
  rentabilidadDesktop3,
} from "../assets";
import { TestimonialSlider } from "../components";
import { redirectURL } from "../constants/functions";

const actionRedirect = (type, option, position) => {
  redirectURL(type, {
    event: "button_click",
    payload: {
      section: "landing traspaso",
      sub_section: "banner",
      option: option,
      position_banner: position,
    },
  });
};

export const itemsConfig = [
  {
    top: "top92",
    title: "Cámbiate a Prima AFP",
    styleTitle: { color: "#4e5258" },
    titleColor: "#4e5258",
    subtitle: "Haz tu solicitud fácil, rápido y totalmente online",
    styleSubTitle: { color: "#4e5258" },
    subtitleColor: "#4e5258",
    contentRight: <TestimonialSlider />,
    background: "#E6FAFA",
    backgroundImages: {
      movil: backMovil,
      desktop: backDesktop,
      desktopMedium: ModalMedium,
      desktopLarge: ModalLarge,
    },
    buttons: [
      {
        text: "cámbiate ya",
        action: () => {
          actionRedirect("traspaso", "cambiate ya", "banner_1");
        },
      },
      {
        text: "Me está ayudando un asesor",
        action: () => {
          actionRedirect("traspasoAsesor", "me estan asesorando", "banner_1");
        },
        variant: "secondary",
      },
    ],
  },
  {
    top: "top86",
    title: "Somos la primera AFP en aceptar",
    title2: "aportes como independiente desde Yape",
    backgroundImages: {
      movil: yapeMovil,
      desktop: yapeDesktop,
      desktopMedium: yapeDesktop2,
      desktopLarge: yapeDesktop3,
    },
    buttons: [
      {
        text: "cámbiate ya",
        action: () => {
          actionRedirect("traspaso", "cambiate ya", "banner_2");
        },
      },
      {
        text: "Me está ayudando un asesor",
        action: () => {
          actionRedirect("traspasoAsesor", "me estan asesorando", "banner_2");
        },
        variant: "secondary",
      },
    ],
  },
  {
    top: "top66",
    title: "Somos la primera AFP en contar con un",
    styleTitle: { color: "#4e5258" },
    title2: "programa de ahorro previsional por consumos",
    backgroundImages: {
      movil: slide3Movil,
      desktop: slide3Desktop,
      desktopMedium: slide3Tablet,
      desktopLarge: slide3DesktopLarge,
    },
    background: "#ffede6",    
    buttons: [
      {
        text: "cámbiate ya",
        action: () => {
          actionRedirect("traspaso", "cambiate ya", "banner_3");
        },
      },
      {
        text: "Me está ayudando un asesor",
        action: () => {
          actionRedirect("traspasoAsesor", "me estan asesorando", "banner_3");
        },
        variant: "secondary",
      },
    ],
  },
  {
    top: "top92",
    title: "Cámbiate a Prima AFP",
    subtitle: "Haz tu solicitud fácil, rápido y totalmente online",
    backgroundImages: {
      movil: wfMovil,
      desktop: wfDesktop,
      desktopMedium: wfDesktop2,
      desktopLarge: wfDesktop3,
    },
    buttons: [
      {
        text: "cámbiate ya",
        action: () => {
          actionRedirect("traspaso", "cambiate ya", "banner_4");
        },
      },
      {
        text: "Me está ayudando un asesor",
        action: () => {
          actionRedirect("traspasoAsesor", "me estan asesorando", "banner_4");
        },
        variant: "secondary",
      },
    ],
  },
  {
    top: "top92",
    title: "Cámbiate a Prima AFP",
    subtitle: "Haz tu solicitud fácil, rápido y totalmente online",
    backgroundImages: {
      movil: carMovil,
      desktop: carDesktop,
      desktopMedium: carDesktop2,
      desktopLarge: carDesktop3,
    },
    buttons: [
      {
        text: "cámbiate ya",
        action: () => {
          actionRedirect("traspaso", "cambiate ya", "banner_5");
        },
      },
      {
        text: "Me está ayudando un asesor",
        action: () => {
          actionRedirect("traspasoAsesor", "me estan asesorando", "banner_5");
        },
        variant: "secondary",
      },
    ],
  },
  {
    top: "top92",
    title: "Somos la mejor AFP del Perú ¡Cámbiate a Prima AFP!",
    styleTitle:  { color: "#4e5258" },
    subtitle: "Premiados por la prestigiosa revista World Finance",
    styleSubTitle:  { color: "#4e5258" },
    backgroundImages: {
      movil: worldMovil,
      desktop: worldDesktop,
      desktopMedium: worldDesktop2,
      desktopLarge: worldDesktop3,
    },
    buttons: [
      {
        text: "cámbiate ya",
        action: () => {
          actionRedirect("traspaso", "cambiate ya", "banner_6");
        },
      },
      {
        text: "Me está ayudando un asesor",
        action: () => {
          actionRedirect("traspasoAsesor", "me estan asesorando", "banner_6");
        },
        variant: "secondary",
      },
    ],
  },
  {
    top: "top92",
    title: "Somos líderes en rentabilidad ¡Cámbiate a Prima AFP!",
    subtitle: "7.88% en promedio cada año en el fondo 2 a 19 años",
    backgroundImages: {
      movil: rentabilidadMovil,
      desktop: rentabilidadDesktop,
      desktopMedium: rentabilidadDesktop2,
      desktopLarge: rentabilidadDesktop3,
    },
    buttons: [
      {
        text: "cámbiate ya",
        action: () => {
          actionRedirect("traspaso", "cambiate ya", "banner_6");
        },
      },
      {
        text: "Me está ayudando un asesor",
        action: () => {
          actionRedirect("traspasoAsesor", "me estan asesorando", "banner_6");
        },
        variant: "secondary",
      },
    ],
  },
];
