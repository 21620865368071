const getValue = (str, currentURL) => {
    const startIndex = currentURL.indexOf(str);
    if (startIndex !== -1) {
        const endIndex = currentURL.indexOf("&", startIndex);    
        const strValue = endIndex !== -1 ? currentURL.substring(startIndex + str.length, endIndex) : currentURL.substring(startIndex + str.length);
        return strValue;
      } else {
        return "not set";
      }
     
};
export const getUtmData = (payload, urlFlow) => {
    sessionStorage.getItem('originalUtmParams') ? urlFlow = sessionStorage.getItem('originalUtmParams') : null;
    console.log('urlFlow en getUtmData', urlFlow);
    payload.utm_medium= getValue("utm_medium=", urlFlow);
    payload.utm_source= getValue("utm_source=", urlFlow);
    payload.utm_campaign= getValue("utm_campaign=", urlFlow);
    payload.utm_term= getValue("utm_term=", urlFlow);
    payload.utm_content= getValue("utm_content=", urlFlow);
    return payload;
};

export const sendToData = (url, type, event, payload, urlFlow) => {
    
    const urlStart = `${process.env.REACT_APP_BASE_URL_TRASPASO}/`;
    var location = 'not set';
    if (url.startsWith(urlStart)) {
        location = url.substring(urlStart.length);
    }
    
    if (location.includes('?')) {
        const parts = location.split('?');
        const sectionBeforeQuestionMark = parts[0];
        location = sectionBeforeQuestionMark;
    }


    location = !url.includes('asesor') ? '/' +location : location; 
    payload.procedure_type = url.includes('asesor') ? 'traspasos digitales - asesor': 'traspasos digitales';
    payload.location = location;
    if(type == 'step' && !url.includes('asesor')){
        getUtmData(payload,urlFlow);
    }
    window.sendToDataLayer(event, payload);
};


export const sendToDataLanding = objectDataLayer => {
    let payload = objectDataLayer.payload
    if(!payload) return;
    const currentUrl = new URL(window.location.href)
    let location = currentUrl.pathname
    if (
      payload.section !== "landing traspaso" &&
      payload.section !== "landing consulta"
    ) {
      if (location.includes("asesor")) {
        payload.procedure_type = "traspasos digitales - asesor"
      } else {
        payload.procedure_type = "traspasos digitales"
      }
    }
    payload.location = location
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.forEach((value, key) => {
      payload[key] = value;
    });
    window.sendToDataLayer(objectDataLayer.event, payload)
  }

export const sanitizar = (str) => {
    const processedString = str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
    const resultString = processedString.replace(/[^\w\s]/gi, '');
    return resultString;
};