import styled from "styled-components";

export const HeadBandContainer = styled.div`
  background-color: #6be1e1;
  .container {
    display: flex;
    max-width: 1404px;
    margin: 0 auto;
    padding: 9px 0px 8px 0px;
    img {
      padding-right: 20px;
    }
    .text {
      font-family: "Calibri";
      font-size: 16px;
      font-weight: 400;
      line-height: 22.66px;
      letter-spacing: 0.0012em;
      text-align: left;
      color: #006464;
      .bold {
        font-weight: 700;
      }
    }
  }
  @media screen and (min-width: 1366px) {
    .container {
      max-width: 1318px;
    }
  }
  @media screen and (min-width: 1500px) {
    .container {
      max-width: 1404px !important;
    }
  }

  @media screen and (min-width: 376px) and (max-width: 1366px) {
    .container {
      margin: 0px 20px;
      img {
        padding-right: 11px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: 72px;
    .container {
      padding-left: 16px;
      padding-top: 13px;
      img {
        padding-right: 11px;
      }
    }
  }
`;
