export const size = {
  mobileXS: "280px",
  mobileS: "320px",
  mobileM: "374px",
  mobileL: "425px",
  tabletS: "600px",
  tablet: "767px",
  tabletM: "960px",
  laptop: "1023px",
  laptopL: "1300px",
  laptopM: "1679px",
  desktop: "2559px",
};

export const device = {
  mobileXS: `(min-width: ${size.mobileXS})`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tabletS: `(min-width: ${size.tabletS})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  laptopM: `(min-width: ${size.laptopM})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const mediaQuery = {
  mobileS: `(min-width: 280px) and (max-width: 376px)`,
  mobileM: `(min-width: 375px) and (max-width: 480px)`,
  mobile: `(min-width: 480px) and (max-width: 700px)`,
  tabletLandscape: `(min-width: 700px) and (max-width: 1024px) and (orientation: landscape)`,
  tablet: `(min-width: 700px) and (max-width: 1024px)`,
  desktop: `(min-width: 1024px) and (max-width: 1280px)`,
  afterDesktop: `(min-width: 1280px)`,
};

export const devices = {
  mobile: `${mediaQuery.mobileS}, only screen and ${mediaQuery.mobile}`,
  tablet: `${mediaQuery.tablet}, only screen and ${mediaQuery.tabletLandscape}`,
  desktop: `${mediaQuery.desktop}, only screen and ${mediaQuery.afterDesktop}`,
};

export const devicesLanding = {
  mobile: `only screen and (max-width: 540px)`,
  tablet: `only screen and (min-width: 500px) and (max-width: 1365px)`,
  desktop: `only screen and (min-width: 1366px) and (max-width: 1599px)`,
  desktopMedium: `only screen and (min-width: 1600px) and (max-width: 1727px)`,
  desktopLarge: `only screen and (min-width: 1728px)`,

};
