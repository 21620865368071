import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StyledDotsWrapper } from "./style";
import { itemsConfig } from "./configItems";
import SliderItemComponent from "./sliderItem";

const MotorSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots) => <StyledDotsWrapper>{dots}</StyledDotsWrapper>,
    onBeforeChange: (current, next) => {
      // Deshabilitar foco en diapositivas no visibles
      const slides = document.querySelectorAll('.slick-slide');
      slides.forEach((slide) => {
        if (slide.getAttribute('aria-hidden') === 'true') {
          slide.querySelectorAll('a, button, input, [tabindex]').forEach((el) => {
            el.setAttribute('tabindex', '-1');
          });
        }
      });
    },
    onAfterChange: (current) => {
      // Rehabilitar foco en la diapositiva activa
      const activeSlide = document.querySelector('.slick-slide[aria-hidden="false"]');
      if (activeSlide) {
        activeSlide.querySelectorAll('a, button, input, [tabindex]').forEach((el) => {
          el.removeAttribute('tabindex');
        });
      }
    }
  };

  return (
    <div>
      <Slider {...settings}>
        {itemsConfig.map((item, index) => (
          <SliderItemComponent key={index} {...item} />
        ))}
      </Slider>
    </div>
  );
};

export default MotorSlider;
